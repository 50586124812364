import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { ReactComponent as ActiveIcon } from '../../../common/icons/activeIcon.svg'
import { ReactComponent as InactiveIcon } from '../../../common/icons/inActiveIcon.svg'

export default function NameEmailColumn({ row }) {
  return (
    <Box display={'flex'} alignItems={'center'} columnGap={2}>
      <Tooltip title={row.userStatus === 'ACTIVE' ? 'Active' : 'Inactive'}>
        {row.userStatus === 'ACTIVE' ? <ActiveIcon /> : <InactiveIcon />}
      </Tooltip>

      <Box display={'flex'} columnGap={1} flexDirection={'column'}>
        <Typography
          className='text-shrink-1'
          style={{ maxWidth: 200 }}
          fontFamily={'Plus Jakarta Sans'}
          fontWeight={600}
          fontSize={14}
          color={'#1B1B26'}
          alignItems={'center'}
        >
          <Tooltip title={row.firstName + ' ' + row.lastName}>
            <span>
              {row.firstName} {row.lastName}
            </span>
          </Tooltip>
        </Typography>
        <Tooltip arrow title={row.email}>
          <Typography
            className='text-shrink-1'
            style={{ maxWidth: 200 }}
            fontFamily={'Plus Jakarta Sans'}
            fontWeight={400}
            fontSize={13}
            color={'#1B1B26'}
          >
            <span>{row.email}</span>
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  )
}
