import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Tooltip, Button, Typography } from '@mui/material'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import TrainingsUsers from './TrainingsUsers'
import { ReactComponent as BackIcon } from '../../../common/icons/back_icon.svg'
import { useGetOneTrainingPackageQuery } from '../../../api/trainingPackages/trainingPackages'
import OptionsTag from '../NewTraining/InfoCards/OptionsTag'
import dayjs from 'dayjs'
import ArchiveButton from './ArchiveButton'
import EditButton from './EditButton'
import StatusComponent from '../TrainingTable/StatusComponent'

export default function TrainingDetails() {
  const navigate = useNavigate()

  const { id } = useParams()
  const { data, isLoading } = useGetOneTrainingPackageQuery(id)

  const nameRef = useRef(null)
  const descriptionRef = useRef(null)

  const [detailsHeight, setDetailsHeight] = useState(0)

  useEffect(() => {
    if (data) {
      const nameHeight = nameRef.current?.offsetHeight || 0
      const descriptionHeight = descriptionRef.current?.offsetHeight || 0
      setDetailsHeight(nameHeight + descriptionHeight)
    }
  }, [data])

  if (isLoading) {
    return (
      <Box display='flex' height='100vh' alignItems='center'>
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      p='32px 48px'
      rowGap={1}
      bgcolor='#F8F8FB'
      minHeight='100vh'
    >
      <Box display='flex' columnGap={3} alignItems='center'>
        <Button
          onClick={() => navigate('/training-packages/')}
          sx={{ fontWeight: 500, textTransform: 'none', fontSize: '16px', color: '#6123FF', fontFamily: 'Satoshi' }}
          startIcon={<BackIcon />}
          variant='text'
        >
          Back to All Trainings
        </Button>
        <StatusComponent status={data.status} />
      </Box>
      <Box display='flex' columnGap='80px'>
        <Box width='70%' ref={nameRef}>
          <Typography
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 900,
              fontFamily: 'Satoshi',
              fontSize: '36px',
              pb: '8px'
            }}
          >
            {data?.name}
          </Typography>
        </Box>
        <Box width='25%' display='flex' columnGap={2}>
          <ArchiveButton name={data.name} id={id} isHidden={data.status === 'ARCHIVED'} />
          <EditButton id={id} isHidden={data.status === 'ARCHIVED' || data.status === 'COMPLETED'} />
        </Box>
      </Box>
      <Box ref={descriptionRef}>
        <Tooltip
          arrow
          placement='top-start'
          title={
            <Typography fontWeight={400} fontSize={14}>
              {data.description}
            </Typography>
          }
        >
          <Typography
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 600,
              color: '#76767F',
              fontFamily: 'Plus Jakarta Sans',
              fontSize: '16px',
              pb: '8px'
            }}
          >
            {data?.description}
          </Typography>
        </Tooltip>
      </Box>
      <Box width='100%' display='flex' columnGap={2} flexWrap='wrap' rowGap={1} alignItems='center'>
        <Typography fontFamily='Plus Jakarta Sans' fontWeight={600} fontSize={14} color='#1B1B26'>
          {data.startDate &&
            data.endDate &&
            `${dayjs(data.startDate).format('MMM DD, YYYY')} - ${dayjs(data.endDate).format('MMM DD, YYYY')}`}
        </Typography>
        <OptionsTag color='#EBEFFF' title='The training is mandatory' isHidden={!data.mandatory} />
        <OptionsTag color='#EBEFFF' title='Send notifications & reminders' isHidden={!data.sendNotifications} />
        <OptionsTag color='#EBEFFF' title='Employees can retake the training' isHidden={!data.retrainingAllowed} />
        <OptionsTag color='#EBEFFF' title='Send via Cyberlift bot on Slack or Teams' isHidden={!data.sendViaBot} />
        <OptionsTag
          color='#E3F5F7'
          title={
            <Box display={'flex'} alignItems={'center'}>
              <Typography fontFamily={'Satoshi'} component={'span'} fontWeight={500} fontSize={14} paddingRight={'5px'}>
                {data.trainingModuleIds?.length}
              </Typography>
              <Typography fontFamily={'Plus Jakarta Sans'} component={'span'} fontSize={14}>
                {data.trainingModuleIds?.length === 1 ? 'Module' : 'Modules'}
              </Typography>
            </Box>
          }
          isHidden={!data.trainingModuleIds?.length}
        />
      </Box>
      <TrainingsUsers detailsHeight={detailsHeight} />
    </Box>
  )
}
