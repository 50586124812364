import React from 'react'

import NameEmailColumn from './NameEmailColumn'
import StatusColumn from './StatusColumn'
import { Box, Typography } from '@mui/material'
import { useLazyGetTrainingUsersQuery } from '../../../api/trainingPackages/trainingPackages'
import dayjs from 'dayjs'

export default function useTrainingsUsersTable({ id }) {
  const [getSimulationUsersList, { data, error, isLoading }] = useLazyGetTrainingUsersQuery()

  const columns = [
    {
      field: 'firstName',
      headerName: 'Name',
      sortable: true,
      stickyLeft: true,
      width: 250,
      align: 'left',
      renderComponent: (value, row) => (
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={250}
          sx={{
            '&:hover': {
              backgroundColor: '#F8F8FB',
              borderRadius: '12px'
            }
          }}
        >
          <NameEmailColumn row={row} />
        </Box>
      )
    },
    {
      field: 'addedAt',
      headerName: 'User added',
      sortable: true,
      align: 'left',
      width: 190,
      renderComponent: (value, row) => (
        <Typography fontWeight={400} fontSize={'14px'} fontFamily={'Plus Jakarta Sans'} color={'#76767F'}>
          {convertToUserTimezone(row.addedAt)}
        </Typography>
      )
    },

    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      align: 'center',
      width: 190,
      renderComponent: (value, row) => (
        <>
          <StatusColumn row={row} />
        </>
      )
    },
    {
      field: 'updatedAt',
      headerName: 'Last status update',
      sortable: true,
      align: 'left',
      width: 190,
      renderComponent: (value, row) => (
        <Typography fontWeight={400} fontSize={'14px'} fontFamily={'Plus Jakarta Sans'} color={'#76767F'}>
          {convertToUserTimezone(row.updatedAt)}
        </Typography>
      )
    },
    {
      field: 'notificationSentAt',
      headerName: 'Notification sent',
      sortable: true,
      align: 'left',
      width: 190,
      renderComponent: (value, row) => (
        <Typography fontWeight={400} fontSize={'14px'} fontFamily={'Plus Jakarta Sans'} color={'#76767F'}>
          {row?.notificationSentAt ? convertToUserTimezone(row.notificationSentAt) : '-'}
        </Typography>
      )
    }
  ]
  const convertToUserTimezone = serverTime => {
    const userTimezone = dayjs.tz.guess()
    const parsedTime = dayjs.utc(serverTime, 'YYYY-MM-DD HH:mm')
    return parsedTime.tz(userTimezone).format('MMMM DD, YYYY, HH:mm')
  }
  function fetchData({ page, size, sort, params }) {
    getSimulationUsersList({ id, page, size, sort, ...params })
  }

  const users = data?.data
  const totalCount = data?.totalCount

  return {
    columns,
    fetchData,
    users,
    error,
    totalCount,
    isLoading
  }
}
