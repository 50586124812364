//eslint-disable
import React, { useContext, useEffect, useState } from 'react'

import './styles.css'
import { Button, Dialog, DialogContent, DialogTitle, Divider, LinearProgress, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import Spacer from 'src/common/components/Spacer'
import companyService from 'src/services/company.service'
import userService from 'src/services/user.service'
import templatesService from 'src/services/templates.service'
import settingsService from 'src/services/settings.service'
import AdConnectDialog from './components/adConnectDialog'
import { useQuery } from 'src/hooks'
import { NavLink, Outlet } from 'react-router-dom'
import { parseJwt } from 'src/heplers'
import OktaConnectDialog from './components/oktaConnectDialog'
import { SettingsContext } from 'src/App'
import OktaSsoConnectDialog from './components/oktaSsoConnectDialog'
import customToastWithAlert from '../../common/components/CustomToastWithAlert'
import ConfigureDialog from './components/configureDialog'

export const ExternalLink = ({ to, text, className }) => (
  <a
    className={`row row-center ${className}`}
    style={{ color: '#276BF2', marginTop: 16 }}
    href={to}
    target='_blank'
    rel='noreferrer'
  >
    <span>{text}</span>
    <svg
      style={{ marginLeft: 8 }}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.875 7.8125L16.8744 3.12562L12.1875 3.125'
        stroke='#276BF2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11.2461 8.75L16.8711 3.125' stroke='#276BF2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M14.375 11.25V16.25C14.375 16.4158 14.3092 16.5747 14.1919 16.6919C14.0747 16.8092 13.9158 16.875 13.75 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V6.25C3.125 6.08424 3.19085 5.92527 3.30806 5.80806C3.42527 5.69085 3.58424 5.625 3.75 5.625H8.75'
        stroke='#276BF2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </a>
)

const InputText = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))

const Settings = () => {
  const query = useQuery()

  const [company, setCompany] = useState(null)
  const [languages, setLanguages] = useState([])
  const [user, setUser] = useState(null)
  const [companyName, setCompanyName] = useState('')
  const [companyNameError, setCompanyNameError] = useState(null)
  const [companyLanguage, setCompanyLanguage] = useState('')
  const [industry, setIndustry] = useState('')
  const [customerId, setCustomerID] = useState('')
  const [mainContact, setMainContact] = useState('')
  const [googleCustomerId, setGoogleCustomerId] = useState('')
  const [outlookCustomerId, setOutlookCustomerId] = useState('')
  const [chooseServicesDialog, setChooseServicesDialog] = useState(false)
  const [inviteAdminDialog, setInviteAdminDialog] = useState(false)
  const [adDialog, setAdDialog] = useState(false)
  const [updateDialog, setUpdadeDialog] = useState(false)
  const [oktaDialog, setOktaDialog] = useState(false)
  const [oktaSsoDialog, setOktaSsoDialog] = useState(false)
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [admins, setAdmins] = useState(null)
  const [services, setServices] = useState(null)
  const [servicesSelected, setServicesSelected] = useState([])
  const [connectors, setConnectors] = useState(null)

  const [showConfigureDialog, setShowConfigureDialog] = useState(false)
  const [selectedConnector, setSelectedConnector] = useState(null)
  const [open, setOpen] = useState(false)

  const [loading, setLoading] = useState(true)

  const [, updateSettings] = useContext(SettingsContext)

  const validateForm = () => {
    let errors = {}

    if (!form.email) {
      errors.email = 'Email is required'
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = 'Email is invalid'
    }

    if (!form.firstName) {
      errors.firstName = 'First Name is required'
    }
    if (!form.lastName) {
      errors.lastName = 'Last Name is required'
    }

    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleConnectTo = (type, update = false) => {
    if (type == 'GOOGLE') {
      settingsService.getGoogleAuthUrl().then(resp => {
        window.location = resp.data
      })
    }
    if (type == 'OUTLOOK') {
      settingsService.getOutlookAuthUrl().then(resp => {
        window.location = resp.data
      })
    }
    if (type == 'SLACK') {
      settingsService.getSlackAuthUrl().then(resp => {
        window.open(resp.data, '_blank').focus()
      })
    }
    if (type == 'SLACK_SIMULATION') {
      settingsService.getSlackSimAuthUrl().then(resp => {
        window.open(resp.data, '_blank').focus()
      })
    }
    if (type == 'AD') {
      setAdDialog(true)
      if (update) {
        setUpdadeDialog(true)
      }
    }
    if (type == 'OKTA') {
      setOktaDialog(true)
    }
    if (type == 'OKTA_SSO') {
      setOktaSsoDialog(true)
    }
  }

  function refreshAdmins() {
    setLoading(true)
    userService
      .getUsers({ systemRole: 'COMPANY_ADMIN' })
      .then(adminsResp => {
        setLoading(false)
        setAdmins(adminsResp.data)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const hanldeAddAdmin = () => {
    if (validateForm()) {
      const payload = {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email
      }

      userService
        .inviteAdmin(payload)
        .then(() => {
          setInviteAdminDialog(false)
          refreshAdmins()
          setSuccessMessage('Invite has been sent')
          setForm({
            ...form,
            firstName: '',
            lastName: '',
            email: ''
          })
        })
        .catch(err => {
          setErrorMessage(err)
        })
    }
  }

  const updateConnectors = () => {
    settingsService.getConnectors().then(resp => {
      setConnectors(resp.data)
    })
  }

  useEffect(() => {
    const userLocal = JSON.parse(localStorage.getItem('user'))
    const user = parseJwt(userLocal.accessToken)

    Promise.all([
      companyService.getCompany(user.companyId),
      companyService.getLanguages(),
      userService.getUser(user.userId),
      userService.getUsers({ systemRole: 'COMPANY_ADMIN' }),
      templatesService.getServices(),
      settingsService.getConnectors()
    ])
      .then(values => {
        setLoading(false)

        const [companyResp, langResp, userResp, adminsResp, templatesResp, connectorsResp] = values

        setCompany(companyResp.data)
        setCompanyName(companyResp.data.name || '')
        setCompanyLanguage(companyResp.data.language || '')
        setIndustry(companyResp.data.description || '')
        setServicesSelected(companyResp.data.usedProducts || [])

        setLanguages(langResp.data)

        setUser(userResp.data)

        setAdmins(adminsResp.data)

        setServices(templatesResp.data)

        setConnectors(connectorsResp.data)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }, [])

  const handleSave = () => {
    companyService
      .update(user.companyId, {
        name: companyName,
        language: companyLanguage,
        description: industry
      })
      .then(() => {
        setShowSaveButton(false)
        setSuccessMessage('Company information has been updated')
        updateSettings()
      })
      .catch(err => {
        if (err.response.data.code == 'COMPANY_ERROR_002') {
          setCompanyNameError(err.response.data.description)
        } else if (err.response.data.code == 'COMPANY_ERROR_003') {
          setCompanyNameError(err.response.data.description)
        } else {
          setErrorMessage('Something went wrong')
        }
      })
  }

  useEffect(() => {
    if (query.get('errorSync')) {
      setErrorMessage({
        response: {
          data: {
            description: query.get('errorSync')
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    if (errorMessage || successMessage) {
      customToastWithAlert({
        message: errorMessage?.response.data.description.replace('[', '"').replace(']', '"') || successMessage,
        type: errorMessage ? 'error' : 'success'
      })
    }
    setErrorMessage(null)
    setSuccessMessage(null)
  }, [errorMessage, successMessage])

  const disconnectConfig = type => {
    setOpen(true)
    setSelectedConnector(type)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onAction = () => {
    settingsService
      .disconnect(selectedConnector)
      .then(() => {
        updateConnectors()
        setOpen(false)
        setShowConfigureDialog(false)
        setSuccessMessage('Provider disconnected')
      })
      .catch(err => {
        console.error(err)
        setErrorMessage('Something went wrong')
      })
  }

  return (
    <div className='row' style={{ padding: '24px' }}>
      <div style={{ flex: 1 }} className='column'>
        {loading && <LinearProgress sx={{ mt: '-20px', mb: '20px' }} />}
        <div className='row row-center'>
          <h1>Settings</h1>
          <Spacer />
          {showSaveButton && (
            <Button
              sx={{
                background: '#6123FF',
                textTransform: 'none'
              }}
              variant={'contained'}
              onClick={handleSave}
            >
              Save changes
            </Button>
          )}
        </div>

        <div className='settings-container column'>
          <Outlet
            context={{
              loading,
              setLoading,
              companyName,
              companyNameError,
              setCompanyName,
              setCompanyNameError,
              companyLanguage,
              setCompanyLanguage,
              setShowSaveButton,
              industry,
              setIndustry,
              setChooseServicesDialog,
              servicesSelected,
              connectors,
              updateConnectors,
              query,
              handleConnectTo,
              admins,
              user,
              setInviteAdminDialog,
              customerId,
              setCustomerID,
              mainContact,
              setMainContact,
              googleCustomerId,
              setGoogleCustomerId,
              outlookCustomerId,
              setOutlookCustomerId,
              setErrorMessage,
              languages,
              setUpdadeDialog,
              showConfigureDialog,
              setShowConfigureDialog,
              selectedConnector,
              setSelectedConnector,
              open,
              setOpen
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: 230,
          flexShrink: 0,
          marginLeft: 10,
          marginTop: 20
        }}
        className='column'
      >
        <NavLink className={'settings-nav-item'} to={'/settings'} end>
          Workspace
        </NavLink>
        <NavLink className={'settings-nav-item'} to={'/settings/plugins'}>
          Plugins and Bots
        </NavLink>
        <NavLink className={'settings-nav-item'} to={'/settings/phishing-simulations'}>
          Phishing Simulations
        </NavLink>
        <NavLink className={'settings-nav-item'} to={'/settings/integrations'}>
          Sync users
        </NavLink>
        <NavLink className={'settings-nav-item'} to={'/settings/sso'}>
          SSO
        </NavLink>
        <NavLink className={'settings-nav-item'} to={'/settings/alerts'}>
          Workflows
        </NavLink>
        <NavLink className={'settings-nav-item'} to={'/settings/members'}>
          Platform Admins
        </NavLink>
      </div>

      <Dialog
        sx={{ '& .MuiPaper-root': { borderRadius: '22px' } }}
        open={inviteAdminDialog}
        onClose={() => {
          setInviteAdminDialog(false)
          setErrors(null)
          setForm({
            ...form,
            firstName: '',
            lastName: '',
            email: ''
          })
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Invite Team Members
        </DialogTitle>
        <DialogContent sx={{ width: '600px' }}>
          <p style={{ marginBottom: '25px' }}>
            Enter the email of the user you want to add to your Workspace administrators
          </p>
          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '18px' }}
            label='Email'
            variant='standard'
            onChange={e => {
              setForm({
                ...form,
                email: e.target.value.replaceAll(' ', '')
              })
              setErrors({ ...errors, email: null })
            }}
            value={form?.email}
            error={errors?.email}
          />
          {errors?.email && (
            <p
              style={{
                marginTop: '-10px',
                marginBottom: '10px',
                fontSize: '14px'
              }}
              className='error-text'
            >
              {errors?.email}
            </p>
          )}
          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '18px' }}
            label='First Name'
            variant='standard'
            onChange={e => {
              setForm({ ...form, firstName: e.target.value })
              setErrors({ ...errors, firstName: null })
            }}
            value={form?.firstName}
            error={errors?.firstName}
          />
          {errors?.firstName && (
            <p
              style={{
                marginTop: '-10px',
                marginBottom: '10px',
                fontSize: '14px'
              }}
              className='error-text'
            >
              {errors?.firstName}
            </p>
          )}
          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '18px' }}
            label='Last Name'
            variant='standard'
            onChange={e => {
              setForm({ ...form, lastName: e.target.value })
              setErrors({ ...errors, lastName: null })
            }}
            value={form?.lastName}
            error={errors?.lastName}
          />
          {errors?.lastName && (
            <p
              style={{
                marginTop: '-10px',
                marginBottom: '10px',
                fontSize: '14px'
              }}
              className='error-text'
            >
              {errors?.lastName}
            </p>
          )}
        </DialogContent>
        <Divider />
        <div className='row'>
          <Button
            onClick={() => {
              setInviteAdminDialog(false)
              setErrors(null)
              setForm({
                ...form,
                firstName: '',
                lastName: '',
                email: ''
              })
            }}
            sx={{
              height: '60px',
              borderRadius: '14px',
              color: '#6123FF',
              borderColor: '#6123FF',
              flex: '1',
              margin: '24px 16px 24px 32px'
            }}
            variant={'outlined'}
          >
            Cancel
          </Button>
          <Button
            onClick={hanldeAddAdmin}
            sx={{
              height: '60px',
              borderRadius: '14px',
              backgroundColor: '#6123FF',
              flex: '1',
              margin: '24px 32px 24px 0px'
            }}
            variant={'contained'}
          >
            Send invite
          </Button>
        </div>
      </Dialog>

      <AdConnectDialog
        update={updateDialog}
        open={adDialog}
        onClose={() => setAdDialog(false)}
        onSuccess={() => {
          updateConnectors()
          setShowConfigureDialog(true)
          setSelectedConnector('AD')
        }}
      />

      <ConfigureDialog
        open={showConfigureDialog}
        onClose={() => setShowConfigureDialog(false)}
        type={selectedConnector}
        handleConnectTo={handleConnectTo}
        onDisconnect={disconnectConfig}
        connectors={connectors}
        onSuccess={() => updateConnectors()}
      />

      <OktaConnectDialog open={oktaDialog} onClose={() => setOktaDialog(false)} onSuccess={() => updateConnectors()} />

      <OktaSsoConnectDialog
        open={oktaSsoDialog}
        onClose={() => setOktaSsoDialog(false)}
        onSuccess={() => updateConnectors()}
      />

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            paddingTop: '8px',
            maxWidth: 'unset',
            width: '684px'
          }
        }}
        open={open}
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Disconnect?
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              fontSize: '14px',
              marginBottom: '80px',
              lineHeight: '22px'
            }}
          >
            Are you sure you want to disconnect this provider?
          </p>
          <div className='row'>
            <Button
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                borderColor: '#D0D0D8',
                color: '#1B1B26'
              }}
              variant={'outlined'}
              fullWidth
              onClick={onClose}
            >
              Cancel
            </Button>
            <div style={{ width: '20px' }}></div>
            <Button
              onClick={onAction}
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                background: '#6123FF'
              }}
              variant={'contained'}
              fullWidth
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Settings
