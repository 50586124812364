import customToastWithAlert from '../CustomToastWithAlert'
import { useEffect } from 'react'

export default function SnackbarCustom({ successMessage, warningMessage, errorMessage, infoMessage, onClose }) {
  useEffect(() => {
    if (successMessage) {
      customToastWithAlert({ message: successMessage, type: 'success' })
      onClose()
    }

    if (warningMessage) {
      customToastWithAlert({ message: warningMessage, type: 'warning' })
      onClose()
    }
    if (errorMessage) {
      customToastWithAlert({ message: errorMessage, type: 'error' })
      onClose()
    }
    if (infoMessage) {
      customToastWithAlert({ message: infoMessage, type: 'info' })
      onClose()
    }
  }, [successMessage, warningMessage, errorMessage, infoMessage, onClose])
}
