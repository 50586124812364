import React, { useState, useContext, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material'
import { styled } from '@mui/material/styles'

import LoadingIndicator from 'src/common/components/LoadingIndicator'
import settingsService from 'src/services/settings.service'

import { SnackBarContext } from 'src/App'
import { ConnectorIcon } from './integrations'
import Spacer from 'src/common/components/Spacer'
import CustomCheckbox from 'src/common/components/CustomCheckbox'
import CyberSwitchV2 from 'src/common/components/SwitchV2'
import { Close } from '@mui/icons-material'
import { toggleElement } from 'src/heplers'

const SelectStyled = styled(Select)(() => ({
  borderRadius: 20,
  margin: 0,
  padding: '0px 16px',
  fontSize: '16px',
  backgroundColor: '#fff',
  '.MuiSelect-select': {
    padding: '13.5px 14px'
  },
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))

const GroupChip = ({ item, items, onRemove, disabled }) => {
  return (
    <div
      className='row row-center'
      style={{
        padding: '2px 3px 2px 18px',
        background: '#6123ff20',
        border: '1px solid #6123ff',
        marginRight: '10px',
        borderRadius: 12,
        color: '#6123ff'
      }}
    >
      <span style={{ marginRight: 10 }}> {items.find(gr => gr.uuid == item)?.name}</span>{' '}
      <IconButton disabled={disabled} size={'small'} sx={{ color: '#6123ff' }} onClick={() => onRemove(item)}>
        <Close color='#fff' />
      </IconButton>{' '}
    </div>
  )
}

const INCLUDE_TYPES = {
  allUsers: 'ALL_USERS',
  allUsersAndGroups: 'ALL_USERS_AND_GROUPS',
  onlySpecificUsers: 'ONLY_SPECIFIC_USERS'
}

const ConfigureDialog = ({ open, onClose, type = '', handleConnectTo, onDisconnect, connectors, onSuccess }) => {
  const { setSuccessMessage, setErrorMessage } = useContext(SnackBarContext)

  const [loading, setLoading] = useState(false)
  const [autoSyncEnabled, setAutoSyncEnabled] = useState(false)
  const [includeType, setIncludeType] = useState(null)
  const [groups, setGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])

  useEffect(() => {
    if (open && connectors) {
      setLoading(true)
      settingsService
        .getGroups(type)
        .then(groupsResp => {
          setGroups(groupsResp.data)

          if (type == 'AD') {
            settingsService
              .getInfo(type)
              .then(resp => {
                const actualGroupsAd = resp.data.groups
                  .map(el => el.uuid)
                  .filter(item => groupsResp.data.map(gr => gr.uuid).includes(item))
                setAutoSyncEnabled(resp.data.autoSync)
                setSelectedGroups(actualGroupsAd)
                setIncludeType(resp.data.syncType)
              })
              .catch(e => {
                console.error(e)
                setErrorMessage('Error fetching data')
              })
          } else {
            const currentConnector = connectors.find(el => el.provider == type)
            const actualGroups = currentConnector.externalGroups
              .map(el => el.uuid)
              .filter(item => groupsResp.data.map(gr => gr.uuid).includes(item))

            setAutoSyncEnabled(currentConnector.autoSync)
            setSelectedGroups(actualGroups)
            setIncludeType(currentConnector.syncType)
          }
        })
        .catch(e => {
          console.error(e)
          setErrorMessage('Error fetching groups')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [open, connectors])

  const clearForm = () => {
    onClose()
    setSelectedGroups([])
  }

  const handleSave = () => {
    setLoading(true)
    const body = {
      groups:
        includeType == INCLUDE_TYPES.onlySpecificUsers ? groups.filter(el => selectedGroups.includes(el.uuid)) : [],
      autoSync: autoSyncEnabled,
      provider: type,
      syncType: includeType
    }

    settingsService
      .setSyncUsersConfig(body)
      .then(() => {
        settingsService
          .sync(type)
          .then(() => {
            onSuccess()
            setSuccessMessage('Users and groups successfully synchronized')
            clearForm()
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(e => {
        setErrorMessage('Sync failed')
      })
  }

  const disconnect = () => {
    onDisconnect(type)
  }

  const enableAutoSync = () => {
    setAutoSyncEnabled(!autoSyncEnabled)
  }

  const handleChange = event => {
    const {
      target: { value }
    } = event
    setSelectedGroups(typeof value === 'string' ? value.split(',') : value)
  }

  const handleRemoveSelectedGroup = id => {
    setSelectedGroups(toggleElement(selectedGroups, id))
  }

  return (
    <>
      <Dialog
        sx={{ '& .MuiPaper-root': { borderRadius: '22px', maxWidth: '750px', width: '100%' } }}
        open={open}
        // onClose={() => {
        //   clearForm()
        // }}
      >
        <DialogContent>
          {loading && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                background: '#00000030',
                zIndex: '2'
              }}
              className='column column-center'
            >
              <LoadingIndicator text={'Please wait'} />
            </div>
          )}
          <div
            style={{
              border: '1px solid #e0e0e0',
              borderRadius: '14px',
              background: '#fafafa',
              padding: '20px',
              marginBottom: '15px'
            }}
          >
            <div className='row'>
              <div style={{ marginRight: 20 }}>
                <ConnectorIcon type={type} />
              </div>
              <div className='column'>
                <p style={{ fontWeight: 900, fontSize: 18, marginBottom: '6px' }}>{type}</p>
                {/* <p
                  style={{
                    fontSize: 13,
                    opacity: 0.7
                  }}
                >
                  Last sync:{' '}
                   {dayjs
                    .utc(updatedAt)
                    .local()
                    .format('DD MMM YYYY HH:mm')}
                4 hours ago
              </p> */}
              </div>
              <Spacer />
              <div className='row'>
                {type == 'AD' && (
                  <Button
                    onClick={() => {
                      handleConnectTo('AD', true)
                    }}
                    variant='outlined'
                    sx={{
                      borderRadius: '14px',
                      color: '#6123FF',
                      borderColor: '#6123FF',
                      textTransform: 'none',
                      margin: '15px 20px 0px 0px'
                    }}
                  >
                    Update credentials
                  </Button>
                )}
                {/* <Button
                  onClick={() => { }}
                  variant='outlined'
                  sx={{
                    borderRadius: '14px',
                    color: '#6123FF',
                    borderColor: '#6123FF',
                    textTransform: 'none',
                    margin: '15px 20px 0px 0px'
                  }}
                >
                  Sync
                </Button> */}
                <Button
                  onClick={disconnect}
                  variant='outlined'
                  sx={{
                    borderRadius: '14px',
                    color: '#B40020',
                    borderColor: '#B40020',
                    textTransform: 'none',
                    margin: '15px 0px 0px 0px'
                  }}
                >
                  Disconnect
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              border: '1px solid #e0e0e0',
              borderRadius: '14px',
              background: '#fafafa',
              padding: '20px',
              marginBottom: '15px'
            }}
          >
            <p style={{ fontWeight: 900, fontSize: 18, marginBottom: '10px' }}>Employees</p>
            <div className='row row-center'>
              <CustomCheckbox
                checked={includeType == INCLUDE_TYPES.allUsers}
                onClick={() => {
                  setIncludeType(INCLUDE_TYPES.allUsers)
                  setSelectedGroups([])
                }}
              />
              <p>Sync all employees</p>
            </div>
            <div className='row row-center'>
              <CustomCheckbox
                checked={includeType == INCLUDE_TYPES.allUsersAndGroups}
                onClick={() => {
                  setIncludeType(INCLUDE_TYPES.allUsersAndGroups)
                  setSelectedGroups([])
                }}
              />
              <p>Sync all employees with all groups and organizational units</p>
            </div>
            <div className='row row-center'>
              <CustomCheckbox
                disabled={groups.length == 0}
                checked={includeType == INCLUDE_TYPES.onlySpecificUsers}
                onClick={() => setIncludeType(INCLUDE_TYPES.onlySpecificUsers)}
              />
              <p>Sync only employees in specific groups and organizational units</p>
            </div>

            <FormControl
              disabled={includeType !== INCLUDE_TYPES.onlySpecificUsers || groups.length == 0}
              sx={{ width: '100%', mb: 2 }}
            >
              <SelectStyled
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                multiple
                value={selectedGroups}
                onChange={handleChange}
                renderValue={selected => selected.map(el => groups.find(gr => gr.uuid == el)?.name).join(', ')}
              >
                {groups.map(el => (
                  <MenuItem key={el.uuid} value={el.uuid}>
                    <CustomCheckbox checked={selectedGroups.includes(el.uuid)} />
                    <ListItemText primary={el.name} />
                  </MenuItem>
                ))}
              </SelectStyled>
            </FormControl>

            <div className='row' style={{ flexWrap: 'wrap' }}>
              {selectedGroups.map(el => (
                <GroupChip
                  disabled={includeType !== INCLUDE_TYPES.onlySpecificUsers}
                  key={el}
                  item={el}
                  items={groups}
                  onRemove={handleRemoveSelectedGroup}
                />
              ))}
            </div>
          </div>
          <div style={{ border: '1px solid #e0e0e0', borderRadius: '14px', background: '#fafafa', padding: '20px' }}>
            <p style={{ fontWeight: 900, fontSize: 18, marginBottom: '10px' }}>Auto-sync</p>

            <div style={{ marginBottom: 10, marginTop: 20 }} className='row'>
              <CyberSwitchV2 checked={autoSyncEnabled} onChange={enableAutoSync} />
              <p
                style={{
                  marginLeft: 12,
                  fontWeight: 600
                }}
              >
                Enable
              </p>
            </div>

            {autoSyncEnabled ? (
              <p>New employees will be synchronized automatically</p>
            ) : (
              <p>Syncronization of new employees/groups is done manually</p>
            )}
          </div>
        </DialogContent>
        <Divider />
        <div className='row'>
          <Button
            disabled={loading}
            onClick={() => {
              clearForm()
            }}
            sx={{
              height: '50px',
              textTransform: 'none',
              borderRadius: '14px',
              color: '#6123FF',
              borderColor: '#6123FF',
              flex: '1',
              margin: '24px 16px 24px 32px'
            }}
            variant={'outlined'}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              height: '50px',
              textTransform: 'none',
              borderRadius: '14px',
              backgroundColor: '#6123FF',
              flex: '1',
              margin: '24px 32px 24px 0px'
            }}
            variant={'contained'}
          >
            Sync
          </Button>
        </div>
      </Dialog>
    </>
  )
}

export default ConfigureDialog
