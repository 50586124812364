import React, { useCallback, useEffect } from 'react'
import { Field, useForm } from 'react-final-form'
import EditorForm from './EditorForm'

export default function CustomEditor({
  name,
  isSystemTemplate,
  templateData,
  setTemplateChanged,
  pageAttachment,
  setPageAttachment,
  fileChunk,
  generateFileChunk
}) {
  const form = useForm()

  const validateEditorContent = useCallback(
    value => value?.includes('[phishingLink]') || fileChunk || value?.includes('qr-in-template.jpg'),
    [fileChunk]
  )

  const validateField = useCallback(
    value => {
      return validateEditorContent(value)
        ? undefined
        : 'Please add at least one phishing QR code, phishing link, or phishing file'
    },
    [isSystemTemplate, validateEditorContent]
  )

  useEffect(() => {
    form.resetFieldState(name)
  }, [fileChunk, form, name])

  return (
    <Field
      name={name}
      validate={validateField}
      render={({ input, meta }) => (
        <EditorForm
          key={`${templateData}`}
          setTemplateChanged={setTemplateChanged}
          pageAttachment={pageAttachment}
          setPageAttachment={setPageAttachment}
          templateData={templateData}
          input={input}
          meta={meta}
          generateFileChunk={generateFileChunk}
        />
      )}
    />
  )
}
