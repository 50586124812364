import React, { useContext, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Autocomplete, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import { LoginInput } from 'src/pages/auth-link'
import { INDUSTRIES, InputSelect } from 'src/pages/registration'

import { TimePicker } from 'rsuite'
import 'rsuite/TimePicker/styles/index.css'
import dayjs from 'dayjs'
import settingsService from 'src/services/settings.service'
import { SnackBarContext } from 'src/App'
import { timezones } from './timezones'

const AutocompleteStyled = styled(Autocomplete)(() => ({
  border: '1px solid #E7E7EE',
  borderRadius: 20,
  padding: '0px 16px',
  fontSize: '16px',
  backgroundColor: '#fff'
}))

const SelectStyled = styled(Select)(() => ({
  border: '1px solid #E7E7EE',
  borderRadius: 20,
  padding: '0px 16px',
  fontSize: '16px',
  backgroundColor: '#fff',
  '.MuiSelect-select': {
    padding: '13.5px 14px'
  },
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))

const options = timezones.map(el => ({ label: el.text, value: el.utc[0] }))

export const Workspace = () => {
  const {
    companyName,
    setCompanyName,
    setShowSaveButton,
    industry,
    setIndustry,
    languages,
    companyLanguage,
    setCompanyLanguage,
    companyNameError,
    setCompanyNameError
  } = useOutletContext()

  const { setSuccessMessage, setErrorMessage } = useContext(SnackBarContext)

  const [wt, setWt] = useState(null)
  const [value, setValue] = useState(null)

  useEffect(() => {
    settingsService
      .getWorkTime()
      .then(resp => {
        setWt(resp.data)
        setValue(options.find(el => el.value == resp.data.timezone))
      })
      .catch(e => {
        console.error(e)
      })
  }, [])

  const timeStringToDate = timeString => {
    const [hours, minutes] = timeString.split(':').map(Number)
    const now = new Date()
    now.setHours(hours, minutes, 0, 0)
    return now
  }

  const updateWorkingTime = newWt => {
    settingsService
      .setWorkTime(newWt)
      .then(() => {
        setSuccessMessage('Successfully updated working time')
      })
      .catch(e => {
        console.error(e)
        setErrorMessage('Something went wrong')
      })
  }

  return (
    <div className='row settings-section'>
      <div className='column'>
        <h2>Workspace</h2>

        <p>Here is some basic information about your company that will be visible to other users on the platform</p>

        <LoginInput
          sx={{ borderRadius: '20px', mb: '20px', mt: '20px' }}
          error={companyNameError}
          label='Name of the company'
          variant='standard'
          value={companyName}
          onChange={e => {
            setCompanyName(e.target.value)
            setShowSaveButton(true)
            setCompanyNameError(null)
          }}
        />
        {companyNameError && (
          <p className='error-text' style={{ margin: '-10px 0px 30px 20px' }}>
            {companyNameError}
          </p>
        )}
        {/* <LoginInput
          sx={{ borderRadius: '20px', mb: '10px' }}
          label='Industry'
          variant='standard'
          value={industry}
          onChange={(e) => {
            setIndustry(e.target.value)
            setShowSaveButton(true)
          }}
        /> */}

        <FormControl fullWidth>
          <InputLabel id='Industry'>Industry</InputLabel>
          <InputSelect
            required
            MenuProps={{
              style: {
                maxHeight: 200
              }
            }}
            sx={{ borderRadius: '20px', mb: '18px' }}
            error={null}
            labelId='Industry'
            value={industry}
            label='Industry'
            onChange={e => {
              setIndustry(e.target.value)
              setShowSaveButton(true)
            }}
          >
            {INDUSTRIES.map(el => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </InputSelect>
        </FormControl>

        <p style={{ margin: '20px 0px' }}>Company Language</p>

        <SelectStyled value={companyLanguage}>
          {languages.map(el => (
            <MenuItem
              onClick={() => {
                setCompanyLanguage(el)
                setShowSaveButton(true)
              }}
              key={el}
              value={el}
            >
              <ListItemText primary={el} />
            </MenuItem>
          ))}
        </SelectStyled>

        {wt && (
          <div className='column' style={{ marginTop: 20 }}>
            <h2 style={{ marginTop: 20 }}>Working Hours</h2>
            <p style={{ maxWidth: '730px', lineHeight: '24px' }}>
              Configure working hours and time zone. These times will be used for phishing simulation emails,
              notifications, and reminders.
            </p>

            <div style={{ margin: '20px 0px' }} className='row row-center'>
              <p style={{ marginRight: 20 }}>Work start time: </p>
              <TimePicker
                placement={'autoVertical'}
                cleanable={false}
                onChange={e => {
                  setWt({ ...wt, workStartTime: dayjs(e).format('HH:mm') })
                  updateWorkingTime({ ...wt, workStartTime: dayjs(e).format('HH:mm') })
                }}
                value={timeStringToDate(wt.workStartTime)}
              />
              <p style={{ marginRight: 20, marginLeft: 20 }}>Work end time: </p>
              <TimePicker
                placement={'autoVertical'}
                cleanable={false}
                onChange={e => {
                  setWt({ ...wt, workEndTime: dayjs(e).format('HH:mm') })
                  updateWorkingTime({ ...wt, workEndTime: dayjs(e).format('HH:mm') })
                }}
                value={timeStringToDate(wt.workEndTime)}
              />
            </div>

            <SelectStyled value={value?.label}>
              {options.map(el => (
                <MenuItem
                  onClick={() => {
                    setValue(el)
                    setWt({ ...wt, timezone: el.value })
                    updateWorkingTime({ ...wt, timezone: el.value })
                  }}
                  key={el.label}
                  value={el.label}
                >
                  <ListItemText primary={el.label} />
                </MenuItem>
              ))}
            </SelectStyled>
          </div>
        )}
      </div>
    </div>
  )
}
